import { SubdivisionType } from '@wix/ambassador-wix-atlas-service-web/types';
import type { Address, AddressInputAddress } from '../types/businessTypes';
import { DispatchType } from '../types/businessTypes';
import { FulfillmentType } from '@wix/ambassador-restaurants-operations-v1-operation/types';

export const TEMP_MENU_ID = '7b656cd2-3049-4f9c-aadc-01861965431f';
export const TEMP_SECTION_ID = 'b8a229a4-50bf-4a6f-854b-611234b9d516';
export const TEMP_ITEM_ID = '86572003-9469-4644-bdab-472288c0ae65';
export const ECOM_APP_ID = '1380b703-ce81-ff05-f115-39571d94dfcd';

export const DEFAULT_CURRENCY_CODE = 'USD';

export const { locale: DEFAULT_LOCALE, timeZone: DEFAULT_TIMEZONE } =
  Intl.DateTimeFormat().resolvedOptions();

export const SOME_TIMEZONE = 'Asia/Jerusalem';
export const ANOTHER_TIMEZONE = 'America/Chicago';
export const SOME_LOCALE = 'en-US';
export const DEFAULT_CURRENCY = '$';
export const DEFAULT_DISPATCH_TYPE = 'pickup';
export const DEFAULT_PICKUP_ADDRESS = 'Our business pickup address';
export const LABELS_LIMIT = 5;

export const ECOM_USER_PREFERENCE_CART_NAMESPACE = 'ecom-platform-cart';

export const MOCK_ADDRESS: Address = {
  country: 'US',
  subdivision: 'NY',
  city: 'New York',
  postalCode: '10028',
  streetAddress: {
    number: '1000',
    name: '5th Avenue',
    apt: '',
    formattedAddressLine: 'The Metropolitan Museum of Art',
  },
  addressLine2: 'add',
  hint: 'hint',
  subdivisionFullname: 'subdivisionFullname',
  formattedAddress: '1000 5th Ave, New York, NY 10028, USA',
  geocode: {
    latitude: 40.7794366,
    longitude: -73.963244,
  },
  countryFullname: 'United States',
  subdivisions: [
    {
      code: 'NY',
      name: 'New York',
      type: SubdivisionType.ADMINISTRATIVE_AREA_LEVEL_1,
    },
    {
      code: 'New York County',
      name: 'New York County',
      type: SubdivisionType.ADMINISTRATIVE_AREA_LEVEL_1,
    },
    {
      code: 'New York',
      name: 'New York',
      type: SubdivisionType.ADMINISTRATIVE_AREA_LEVEL_1,
    },
    {
      code: 'US',
      name: 'United States',
      type: SubdivisionType.ADMINISTRATIVE_AREA_LEVEL_1,
    },
  ],
};

export const MOCK_ADDRESS_INPUT: AddressInputAddress = {
  formatted: '1000 5th Ave, New York, NY 10028, USA',
  streetAddress: {
    number: '1000',
    name: '5th Avenue',
    apt: '',
    formattedAddressLine: 'The Metropolitan Museum of Art',
  },
  subdivision: 'NY',
  city: 'New York',
  country: 'US',
  postalCode: '10028',
  location: {
    latitude: 40.7794366,
    longitude: -73.963244,
  },
};

export const FULFILLMENT_TYPE_TO_DISPATCH_TYPE = {
  [FulfillmentType.PICKUP]: DispatchType.PICKUP,
  [FulfillmentType.DELIVERY]: DispatchType.DELIVERY,
};

// Modifier service restriction for the filter operators on `query` requests
export const MAX_FILTER_OPERATOR_LENGTH = 200;
