import type { AppDescriptor, CustomActionConfig } from '@wix/editor-platform-sdk-types';
import type { TFunction } from '@wix/yoshi-flow-editor';
import { APP_DESCRIPTOR_ACTIONS } from './actions';
import { getIsAppInstalled } from './editor.utils';
import { APP_DEF_IDS } from '@wix/restaurants-consts';
import type { EditorSDK } from '@wix/platform-editor-sdk';

const ICONS = {
  SETTINGS: 'appManager_settingsAction',
  MENUS: 'restaurantsPageType',
  PAGES: 'appManager_pagesAction',
  ORDERS: 'restaurantsOrders',
};

export const getAppDescriptor = async (
  editorSDK: EditorSDK,
  t: TFunction
): Promise<AppDescriptor> => {
  const isAppInstalled = await getIsAppInstalled(editorSDK, APP_DEF_IDS.menus);

  const menusSection = isAppInstalled
    ? ({
        title: t('my-business.custom-actions.menus'),
        icon: ICONS.MENUS,
        type: 'dashboard',
        actionId: APP_DESCRIPTOR_ACTIONS.MANAGE_MENUS,
      } as CustomActionConfig)
    : undefined;

  const appDescriptor = {
    customActions: [
      {
        title: t('my-business.custom-actions.view-orders'),
        icon: ICONS.ORDERS,
        type: 'dashboard',
        actionId: APP_DESCRIPTOR_ACTIONS.VIEW_ORDERS,
      },
      {
        title: t('my-business.custom-actions.manage-pages'),
        icon: ICONS.PAGES,
        type: 'dashboard',
        actionId: APP_DESCRIPTOR_ACTIONS.MANAGE_PAGES,
      },
    ],
    mainActions: [
      {
        title: t('my-business.custom-actions.order-settings'),
        icon: ICONS.SETTINGS,
        actionId: APP_DESCRIPTOR_ACTIONS.MANAGE_ORDER_SETTINGS,
      },
    ],
    defaultActions: {},
  } as AppDescriptor;

  menusSection && appDescriptor.customActions.splice(1, 0, menusSection);
  return appDescriptor;
};
