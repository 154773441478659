import { APP_DEF_IDS, DASHBOARD_URLS } from '@wix/restaurants-consts';
import {
  APP_MARKET_PANEL,
  BM_OPERATION_QUERY_PARAM,
  OLO_DASHBOARD_URLS,
} from '../appConsts/consts';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { ValueOf } from 'root/types/valueOf';

const openDashboardPanel = (editorSDK: FlowEditorSDK, appDefId: string, url: string) => {
  editorSDK.editor
    .openDashboardPanel(appDefId, {
      url,
      closeOtherPanels: false,
    })
    .then(async () => {
      await editorSDK.document.application.livePreview.refresh('', {
        shouldFetchData: true,
        source: '',
      });
      await editorSDK.document.application.reloadManifest();
    });
};

export const openAppMarketPanel = (editorSDK: FlowEditorSDK, appDefId: string) => {
  editorSDK.editor.openPanel(
    APP_MARKET_PANEL,
    {
      urlParams: { slug: appDefId },
    },
    true
  );
};

export const openManageMenuDashboardPanel = (editorSDK: FlowEditorSDK) => {
  openDashboardPanel(editorSDK, APP_DEF_IDS.menus, DASHBOARD_URLS.menusUrl);
};

export const OLODashboardPanel = (editorSDK: FlowEditorSDK) => ({
  openSettings: (operationId?: string) =>
    openOLODashboardPanelByUrl(
      editorSDK,
      `${OLO_DASHBOARD_URLS.settings}${
        operationId ? `${BM_OPERATION_QUERY_PARAM}${operationId}` : ''
      }`
    ),
  openViewOrders: () => openOLODashboardPanelByUrl(editorSDK, OLO_DASHBOARD_URLS.viewOrders),
});

export const openMenusAppMarketPanel = (editorSDK: FlowEditorSDK) => {
  openAppMarketPanel(editorSDK, APP_DEF_IDS.menus);
};

const openOLODashboardPanelByUrl = (
  editorSDK: FlowEditorSDK,
  url: ValueOf<typeof OLO_DASHBOARD_URLS>
) => {
  openDashboardPanel(editorSDK, APP_DEF_IDS.orders, url);
};
