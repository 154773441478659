import type {
  DispatchesInfo,
  DispatchInfo,
  DispatchState,
  DispatchStateByLocation,
  Operation,
} from '../types/businessTypes';
import { DispatchType } from 'root/types/businessTypes';
import { makeAutoObservable, toJS, configure, action, observable, computed } from 'mobx';
import { context } from 'root/context/RootContext';

configure({ isolateGlobalState: true });

class _DispatchState {
  dispatchStateByLocation: DispatchStateByLocation = new Map<string, DispatchState>();
  operations: Operation[] = [];
  currentOperationId?: string;
  isLoading?: boolean = true;
  dispatchType?: DispatchType;
  isLocationSelected?: boolean;

  constructor() {
    makeAutoObservable(this, {
      init: action,
      update: action,
      setCurrentOperationId: action,
      setOperations: action,
      setIsLocationSelected: action,
      currentOperationId: observable,
      isLocationSelected: observable,
      dispatchType: observable,
      dispatchInfo: computed,
      dispatchesInfo: computed,
    });
  }

  get currentOperation() {
    return this.operations.find((operation) => operation.id === this.currentOperationId);
  }

  get locationId() {
    return this.currentOperation?.locationId ?? '';
  }

  get operationId() {
    return this.currentOperationId;
  }

  get dispatchStateByCurrentLocation(): DispatchState {
    return this.dispatchStateByLocation?.get(this.locationId) ?? ({} as DispatchState);
  }

  get dispatchesInfo(): DispatchesInfo {
    return this.dispatchStateByCurrentLocation.dispatchesInfo ?? ({} as DispatchesInfo);
  }

  get dispatchInfo(): DispatchInfo {
    return toJS(this.dispatchesInfo?.[this.selectedDispatchType]) ?? {};
  }

  get selectedDispatchType(): DispatchType {
    return this.dispatchType ?? DispatchType.PICKUP;
  }

  get isMultiLocation() {
    return this.hasMultiLocationConfiguredDispatches;
  }

  init({
    dispatchStateByLocation,
    operations,
    operationId,
    isLoading,
    dispatchType,
    isLocationSelected,
  }: {
    dispatchStateByLocation: DispatchStateByLocation;
    operations: Operation[];
    operationId: string;
    isLoading?: boolean;
    dispatchType?: DispatchType;
    isLocationSelected?: boolean;
  }) {
    if (dispatchStateByLocation instanceof Map) {
      this.dispatchStateByLocation = dispatchStateByLocation;
    }
    this.isLoading = isLoading;
    this.currentOperationId = operationId;
    this.operations = operations;
    this.dispatchType = dispatchType ?? this.dispatchStateByCurrentLocation.selectedDispatchType;
    context.PersistDataService?.setDispatchState(dispatchStateByLocation);
    this.isLocationSelected = isLocationSelected;
  }

  update(dispatchType: DispatchType, dispatchInfo: DispatchInfo) {
    const currentDispatchInfo = this.dispatchesInfo[dispatchType];
    this.dispatchesInfo[dispatchType] = {
      ...currentDispatchInfo,
      ...dispatchInfo,
    };
    this.dispatchType = dispatchType;
    this.dispatchStateByCurrentLocation.selectedDispatchType = dispatchType;
    context.PersistDataService?.setDispatchState(this.state);
  }

  setDispatchType(dispatchType: DispatchType) {
    this.dispatchType = dispatchType;
  }

  setOperations(operations: Operation[]) {
    this.operations = operations;
  }

  setIsLocationSelected(isLocationSelected: boolean) {
    this.isLocationSelected = isLocationSelected;
  }

  setCurrentOperationId(operationId: string) {
    this.currentOperationId = operationId;
  }

  get currentOrFirstLocationByConfiguredDispatchTypes() {
    if (!this.configuredDispatchTypesForCurrentLocations.length) {
      const locationId = this.runActionOnEachLocation<string | undefined>(
        (_locationId, dispatchState) => {
          if (dispatchState?.configuredDispatchTypes.length) {
            return _locationId;
          }
        }
      );
      if (locationId) {
        return locationId;
      }
    }
    return this.locationId;
  }

  get configuredDispatchTypes() {
    const dispatchTypes: DispatchType[] = [];
    this.runActionOnEachLocation((_, dispatchState) => {
      dispatchTypes.push(...(dispatchState?.configuredDispatchTypes ?? []));
    });
    return [...new Set(dispatchTypes)];
  }

  get configuredDispatchTypesForCurrentLocations() {
    return this.dispatchStateByCurrentLocation.configuredDispatchTypes ?? [];
  }

  get hasMultiLocationConfiguredDispatches() {
    let cnt = 0;
    const multiLocationConfigures = this.runActionOnEachLocation<boolean | undefined>(
      (_, dispatchState) => {
        if (dispatchState?.configuredDispatchTypes.length) {
          if (cnt === 1) {
            return true;
          }
          cnt++;
        }
      }
    );

    return multiLocationConfigures ?? false;
  }

  runActionOnEachLocation<T>(_action: (locationId: string, dispatchState?: DispatchState) => T) {
    const locationIds = [...this.dispatchStateByLocation.keys()];
    for (const locationId of locationIds) {
      const res = _action(locationId, this.dispatchStateByLocation.get(locationId));
      if (res) {
        return res;
      }
    }
  }

  get availableDispatchTypes() {
    const dispatchTypes: DispatchType[] = [];
    this.runActionOnEachLocation((_, dispatchState) => {
      dispatchTypes.push(...(dispatchState?.availableDispatchTypes ?? []));
    });
    return [...new Set(dispatchTypes)];
  }

  get availableDispatchTypesForCurrentLocation() {
    return this.dispatchStateByCurrentLocation.availableDispatchTypes ?? [];
  }

  get hasAvailableDispatches() {
    return this.availableDispatchTypes.length > 0;
  }

  get hasConfiguredDispatches() {
    return this.configuredDispatchTypes.length > 0;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  getAvailableDispatchTypesByLocation(locationId: string) {
    const dispatches = locationId
      ? this.dispatchStateByLocation.get(locationId)
      : this.dispatchStateByCurrentLocation;
    return dispatches?.availableDispatchTypes ?? [];
  }

  getShippingDetails() {
    const dispatchInfo = this.dispatchInfo;
    const shouldHaveTimeRange = !dispatchInfo.selectedTimeSlot?.startsNow;
    const timeRange = shouldHaveTimeRange
      ? {
          start: dispatchInfo.selectedTimeSlot!.startTime.toUTC().valueOf(),
          end: dispatchInfo.selectedTimeSlot!.endTime.toUTC().valueOf(),
        }
      : undefined;

    return {
      locationId: dispatchState.isLocationSelected
        ? dispatchState.locationId || undefined
        : undefined,
      address: toJS(dispatchInfo.address),
      dispatchType: toJS(this.selectedDispatchType),
      timeRange,
    };
  }

  get state(): DispatchStateByLocation {
    return toJS(this.dispatchStateByLocation);
  }

  isDispatchAvailable(dispatchType: DispatchType, currentLocationId?: boolean) {
    const dispatchTypes = currentLocationId
      ? this.availableDispatchTypesForCurrentLocation
      : this.availableDispatchTypes;
    return dispatchTypes.includes(dispatchType);
  }

  isSelectedDispatchAvailable() {
    return this.isDispatchAvailable(this.selectedDispatchType, true);
  }

  isDispatchConfigured(dispatchType: DispatchType) {
    return this.configuredDispatchTypes.includes(dispatchType);
  }
}

export const dispatchState = new _DispatchState();
