import type {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  TFunction,
  WidgetBuilder,
} from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { FULFILLMENT_PICKER_WIDGET_COMPONENT_IDS } from 'root/appConsts/blocksIds';
import { HEADER_FULFILLMENT_PICKER_DESIGN_PANEL_TABS } from 'root/appConsts/designPanelTabs';
import { configureWidgetDesign } from 'root/editor/editor.utils';
import { getRole } from 'root/utils/utils';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder: WidgetBuilder,
  _: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI
) => {
  const t = flowAPI.translations.t as TFunction;

  widgetBuilder.set({
    displayName: 'Fulfillment Picker',
    nickname: 'FulfillmentPickerWidget',
  });
  widgetBuilder
    .gfpp()
    .set('mainAction1', { behavior: 'HIDE' })
    .set('mainAction2', { behavior: 'HIDE' })
    .set('pinMode', { behavior: 'HIDE' })
    .set('settings', { behavior: 'HIDE' })
    .set('design', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' });
  widgetBuilder.gfpp('mobile').set('hide', { behavior: 'HIDE' });

  widgetBuilder.behavior().set({
    rotatable: false,
    canReparent: false,
    duplicatable: false,
    preventHide: true,
    removable: false,
    resizable: false,
    toggleShowOnAllPagesEnabled: false,
  });

  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('design-panel.header-fulfillment-picker.label'),
      actionType: 'design',
      actionId: '',
    })
    .set('design', {
      behavior: 'DEFAULT',
    })
    .set('add', {
      behavior: 'HIDE',
    });

  widgetBuilder.configureConnectedComponents(
    getRole(FULFILLMENT_PICKER_WIDGET_COMPONENT_IDS.GENERAL.container),
    (componentBuilder) => {
      componentBuilder.behavior().set({ preventHide: true });
    }
  );

  configureWidgetDesign({
    widgetBuilder,
    title: t('design-panel.header-menu.title'),
    tabs: HEADER_FULFILLMENT_PICKER_DESIGN_PANEL_TABS,
    t,
  });
};
