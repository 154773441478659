import { DispatchType } from '../types/businessTypes';

export const OLO_WIDGET_COMPONENT_IDS = {
  menuContainer: '#menu1',
  header: '#header',
  navigationMenu: '#navigation1',
  oloMultiStateBox: '#multiStateBox1',
  errorStateTitle: '#errorStateTitle',
  errorStateSubtitle: '#errorStateSubtitle',
  errorState: '#errorState',
  globalBackground: '#globalBackground',
} as const;

export const HEADER_WIDGET_COMPONENT_IDS = {
  fulfillmentPicker: '#fulfillmentPicker',
  addressInfo: '#addressInfo',
  timeInfo: '#timeInfo',
  addressInfoLine: '#addressInfoLine',
  timeInfoLine: '#timeInfoLine',
  minOrder: '#minOrder',
  deliveryFeeWrapper: '#deliveryFeeWrapper',
  deliveryFeeContainer: '#deliveryFeeContainer',
  minOrderWrapper: '#minOrderWrapper',
  minOrderContainer: '#minOrderContainer',
  acceptingOrderWrapper: '#box14',
  deliveryFee: '#deliveryFee',
  freeDelivery: '#freeDelivery',
  freeDeliveryWrapper: '#freeDeliveryWrapper',
  freeDeliveryContainer: '#freeDeliveryContainer',
  acceptingOrder: '#text8',
  dispatchTypeContainer: '#dispatchTypeContainer',
  dispatchType: '#dispatchType',
  dispatchRadioButton: '#dispatchRadioButton',
  headerImage: '#imageX1',
  headerTitle: '#headerTitle',
  headerDescription: '#headerDescription',
  headerContainer: '#oloHeader',
  addressChange: '#addressChange',
  timeChange: '#timeChange',
  timeIcon: '#vectorImage3',
  locationIcon: '#vectorImage5',
  badgesContainer: '#box13',
  titleAndDescContainer: '#titleAndDescriptionWrapper',
  acceptOrders: '#text8',
  statusIndicator: '#statusIndicator',
  fulfillmentContainer: '#fulfillmentContainer',
  locationNameWrapper: '#locationNameWrapper',
  locationName: '#locationName',
};

export const DISPATCH_MODAL_WIDGET_COMPONENT_IDS = {
  timeMultiState: '#timeMultiState',
  timeSlots: '#timeSlots',
  dateSlots: '#dateSlots',
  dateTimeSlots: '#dropdown2',
  sameDay: '#sameDay',
  saveButton: '#saveButton',
  schedulingPicker: '#schedulingPicker',
  fulfillmentPicker: '#fulfillmentPicker1',
  dispatchStateBox: '#dispatchStateBox',
  addressInput: '#addressInput1',
  datePicker: '#datePicker1',
  asapText: '#asapText',
  errorContainer: '#errorContainer',
  errorMessage: '#errorText',
  closeButton: '#button1',
  pickupAddress: '#text16',
  pickupHeader: '#text17',
  datePickerContainer: '#preorder',
  title: '#text2',
  asapTime: '#text13',
  preOrderTime: '#text15',
  multipleDelivery: '#multipleDelivery',
  locationLobby: '#locationLobby',
  changeLocation: '#changeLocation',
  NotAcceptingError: '#NotAcceptingError',
  NotAcceptingErrorText: '#NotAcceptingErrorText',
  deliverFromSubtitle: '#deliverFromSubtitle',
  deliveryFromAddress: '#deliveryFromAddress',
  safetyBoxForError: '#safteyBoxForError',
  safetyBoxForChangeLocation: '#safteyBoxForChangeLocation',
  safetyBoxForChangeLocationContainer: '#branchChange',
  safetyBoxForChangeLocationIcon: '#vectorImage1',
  safetyBoxForChangeLocationText: '#text27',
  pickupLocationName: '#text26',
  pickupLocationAddress: '#text25',
  acceptOrdersText: '#text24',
  statusIndicator: '#box48',
  pickupLocationPicker: '#selectableContainer2',
  buttonStateBox: '#buttonStateBox',
  nextButton: '#nextButton',
  locationNamePickupDispatch: '#locationNamePickupDispatch',
};

export const MENU_WIDGET_COMPONENT_IDS = {
  menuDivider: '#menuDivider',
  menuStatus: '#menuStatus',
  menuStatusText: '#menuStatusText',
  menuStatusButton: '#menuStatusButton',
  menuTitle: '#menuTitle',
  menuDescription: '#menuDescription',
  repeaterMenus: '#repeaterMenus',
  sectionContainer: '#section1',
  menuEmptystate: '#menuEmptystate',
  menuMultiStateBox: '#multiStateBox1',
  menuEmptyStateTitle: '#emptyStateTitle',
  menuEmptyStateSubtitle: '#emptyStateSubtitle',
} as const;

export const SECTION_WIDGET_COMPONENT_IDS = {
  sectionEmptystate: '#sectionEmptystate',
  dishesContainer: '#dishes',
  repeaterSections: '#repeaterSections',
  sectionTitle: '#sectionTitle',
  sectionDescription: '#sectionDescription',
  sectionMultiStateBox: '#multiStateBox1',
  sectionEmptyStateTitle: '#emptyStateTitle',
  sectionEmptyStateSubtitle: '#emptyStateSubtitle',
} as const;

export const DISHES_WIDGET_COMPONENT_IDS = {
  dishContainer: '#box1',
  dishesEmptyState: '#dishesEmpty',
  repeaterItems: '#repeaterItems',
  itemContainer: '#item',
  itemDescription: '#itemDescription',
  itemPrice: '#itemPrice',
  itemTitle: '#itemTitle',
  itemTitleWrapper: '#titleWrapper',
  itemImage: '#itemImage',
  itemCounter: '#itemCounter',
  itemCounterValue: '#counterNum',
  labelContainer: '#labelContainer',
  label: (idx: number) => `#label${idx}`,
  dishesWidgetMultiStateBox: '#multiStateBox2',
  dishStateMultiStateBox: '#multiStateBox1',
  dishContainerStripBox: '#regular',
  dishContainerCardBox: '#box7',
  dishes: '#dishes',
  dishEmptyStateTitle: '#emptyStateTitle',
  dishEmptyStateSubtitle: '#emptyStateSubtitle',
  itemsWrapper: '#itemsWrapper',
  repeaterWrapper: '#repeaterWrapper',
  additionalLabelsCounter: '#additionalLabels',
} as const;

export const UNAVAILABLE_DISHES_COMPONENT_IDS = {
  itemContainer: '#item',
  itemContainerStripBox: '#outofStock',
  itemContainerCardBox: '#box10',
  itemDescription: '#outOfStockItemDescription',
  itemPrice: '#outOfStockPrice',
  itemTitle: '#outOfStockItemTitle',
  itemTitleWrapper: '#outOfStocktitleWrapper',
  itemImage: '#outOfStockItemImage',
  itemCounter: '#outOfStockItemCouner',
  itemCounterValue: '#outOfStockItemCounerValue',
  labelContainer: '#outOfStockLabelsContainer',
  label: (idx: number) => `#outOfStockLabel${idx}`,
  additionalLabelsCounter: '#outOfStockAdditionalLabels',
} as const;

export const ITEM_MODAL_COMPONENT_IDS = {
  wrapper: '#box2',
  title: '#title',
  subTitle: '#subTitle',
  addToCart: '#addTocart',
  counterContainer: '#counterContainer',
  increaseButton: '#increase',
  decreaseButton: '#decrease',
  counterInput: '#counterInput',
  price: '#price',
  image: '#proGallery1',
  labelsContainer: '#labelsContainer',
  specialRequest: '#specialRequest',
  labelInnerContainer: '#box16',
  label: '#label',
  labelIcon: (idx: number) => `#labelIcon${idx}`,
  labelName: (idx: number) => `#labelName${idx}`,
  close: '#button2', // TODO - change id
  modifiersContainer: '#repeater1',
  modifierGroupContainer: '#box12',
  multiSelect: `#checkboxGroup1`,
  singleSelect: `#radioGroup1`,
  modifiersMultiState: '#multiStateBox1',
  priceVariantsSingleSelect: `#radioGroup2`,
  modifiersError: '#errorMulti',
  modifiersErrorText: '#textErrorMulti',
  modifiersSingleError: '#errorSingle',
  modifiersSingleErrorText: '#textErrorSingle',
  quantityText: '#text1',
  dishStatus: '#dishStatus',
  dishStatusText: '#dishStatusText',
  dishStatusButton: '#itemStatusButton',
} as const;

export const HEADER_COMPONENT_IDS = {
  // TODO - change ids
  dispatchPicker: '#box26',
  close: '#button1',
} as const;

export const NAVIGATION_TOOLBAR_IDS = {
  cartButton: '#cartButton2' as const,
  menuDropdown: '#menuDropdown' as const,
  navigationDivider: '#navigationDivider' as const,
  sectionTabs: '#sectionTabs' as const,
  navigationContainer: '#navigation1' as const,
  box: '#navigationbox' as const,
  innerContainer: '#box2' as const,
} as const;

export const NON_SELECTABLE_DISHES_ELEMENTS_IDS = [
  DISHES_WIDGET_COMPONENT_IDS.itemContainer,
  DISHES_WIDGET_COMPONENT_IDS.labelContainer,
  DISHES_WIDGET_COMPONENT_IDS.itemImage,
  DISHES_WIDGET_COMPONENT_IDS.itemDescription,
  DISHES_WIDGET_COMPONENT_IDS.itemTitle,
  DISHES_WIDGET_COMPONENT_IDS.itemPrice,
  DISHES_WIDGET_COMPONENT_IDS.itemCounter,
  DISHES_WIDGET_COMPONENT_IDS.dishContainerStripBox,
  DISHES_WIDGET_COMPONENT_IDS.dishContainerCardBox,
  UNAVAILABLE_DISHES_COMPONENT_IDS.itemContainer,
  UNAVAILABLE_DISHES_COMPONENT_IDS.itemContainerStripBox,
  UNAVAILABLE_DISHES_COMPONENT_IDS.itemContainerCardBox,
  UNAVAILABLE_DISHES_COMPONENT_IDS.itemImage,
  UNAVAILABLE_DISHES_COMPONENT_IDS.itemDescription,
  UNAVAILABLE_DISHES_COMPONENT_IDS.itemTitle,
  UNAVAILABLE_DISHES_COMPONENT_IDS.itemPrice,
  UNAVAILABLE_DISHES_COMPONENT_IDS.itemCounter,
  UNAVAILABLE_DISHES_COMPONENT_IDS.labelContainer,
  DISHES_WIDGET_COMPONENT_IDS.repeaterItems,
  DISHES_WIDGET_COMPONENT_IDS.dishes,
  DISHES_WIDGET_COMPONENT_IDS.itemsWrapper,
  DISHES_WIDGET_COMPONENT_IDS.repeaterWrapper,
];

export const FULFILLMENT_PICKER_WIDGET_COMPONENT_IDS = {
  [DispatchType.PICKUP]: {
    multiStateBox: '#pickupContainer',
    selected: '#pickupSelected',
    disabled: '#pickupDisable',
    default: '#pickupDefault',
    selectedText: '#pickupSelectedText',
    disabledText: '#pickupDisableText',
    defaultText: '#pickupDefaultText',
  } as const,
  [DispatchType.DELIVERY]: {
    multiStateBox: '#deliveryContainer',
    selected: '#deliverySelected',
    disabled: '#deliveryDisable',
    default: '#deliveryDefault',
    selectedText: '#deliverySelectedText',
    disabledText: '#deliveryDisableText',
    defaultText: '#deliveryDefaultText',
  } as const,
  GENERAL: {
    container: '#box2',
  } as const,
} as const;

export const ERROR_MODAL_COMPONENT_IDS = {
  container: '#box1',
  title: '#text1',
  content: '#text2',
  close: '#button1',
} as const;

export const CART_BUTTON_COMPONENT_IDS = {
  container: '#InnerCartBox' as const,
  viewCartBox: '#Viewcart' as const,
  button: '#Viewcartbutton' as const,
  divider: '#Viewcartdivider' as const,
} as const;

export const POPULAR_DISHES_REPEATER_WIDGET_COMPONENT_IDS = {
  sliderRepeater: '#sliderRepeater',
  popularDishesMultiStateBox: '#multiStateBox1',
  container: '#regular',
  arrowLeft: '#leftArrowButton',
  arrowRight: '#rightArrowButton',
  popularDishesRepeater: '#sliderRepeater',
  itemContainer: '#box2',
  dishDetails: '#box3',
  dishName: '#text1',
  dishPrice: '#text2',
  dishImg: '#imageX1',
  labelContainer: '#labelContainer',
  label: (idx: number) => `#label${idx}`,
  additionalLabelsCounter: '#additionalLabels',
} as const;

export const GHOST_STATE_COMPONENT_IDS = {
  container: '#startOrder' as const,
  label: '#menuStatusText' as const,
  button: '#startOrderButton' as const,
} as const;
