import type {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  TFunction,
  WidgetBuilder,
} from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { HELP_IDS, PANEL_IDS } from '../../appConsts/consts';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder: WidgetBuilder,
  _: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI
) => {
  widgetBuilder.set({
    displayName: 'Order Method',
    nickname: 'DispatchModalWidget',
  });

  const t = flowAPI.translations.t as TFunction;

  widgetBuilder.behavior().set({
    rotatable: false,
    canReparent: false,
    duplicatable: false,
    preventHide: true,
    removable: false,
    resizable: false,
    toggleShowOnAllPagesEnabled: false,
  });

  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('dispatchModal.gfpp.orderSettings'),
      actionId: PANEL_IDS.ordersSettings,
    })
    .set('help', { id: HELP_IDS.DISPATCH_MODAL });
};
