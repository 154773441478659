import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import type {
  ConnectedComponentsBuilder,
  EditorScriptFlowAPI,
  FlowEditorSDK,
  TFunction,
  WidgetBuilder,
} from '@wix/yoshi-flow-editor';
import { HEADER_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import {
  HEADER_BADGES_DESIGN_PANEL_TABS,
  HEADER_FULFILLMENT_PICKER_DESIGN_PANEL_TABS,
  HEADER_TIME_LOCATION_DESIGN_PANEL_TABS,
  HEADER_TITLE_CONTAINER_DESIGN_PANEL_TABS,
} from '../../appConsts/designPanelTabs';
import { getRole } from '../../utils/utils';
import { PANEL_IDS } from '../../appConsts/consts';
import { configureWidgetDesign, disableElementsSelection } from 'root/editor/editor.utils';
import { KB_LINKS } from 'root/appConsts/kb-links';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder: WidgetBuilder,
  _: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI
) => {
  const t = flowAPI.translations.t as TFunction;
  widgetBuilder.set({
    displayName: 'Widget Header',
    nickname: 'HeaderWidget',
  });

  widgetBuilder.behavior().set({ removable: false, preventHide: true });

  widgetBuilder.configureConnectedComponents(
    getRole(HEADER_WIDGET_COMPONENT_IDS.headerImage),
    (componentBuilder) => {
      componentBuilder.set({
        displayName: t('widget.header.image.display-name'),
      });
      componentBuilder
        .gfpp()
        .set('layout', {
          actionId: PANEL_IDS.imageLayout,
        })
        .set('design', { behavior: 'DEFAULT' })
        .set('crop', { behavior: 'HIDE' })
        .set('link', { behavior: 'HIDE' });

      componentBuilder.gfpp('mobile').set('layout', {
        actionId: PANEL_IDS.imageLayout,
      });
    }
  );

  widgetBuilder.configureConnectedComponents(
    getRole(HEADER_WIDGET_COMPONENT_IDS.fulfillmentContainer),
    (componentBuilder) => {
      componentBuilder.set({
        displayName: t('widget.header.fulfillment-info.display-name'),
      });
      componentBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('menu_olo.gfpp.ordersSettings'),
          actionId: PANEL_IDS.ordersSettings,
        })
        .set('help', { id: KB_LINKS.FULFILMENT_INFO_HELP.id });

      configureWidgetDesign({
        widgetBuilder: componentBuilder,
        title: t('design-panel.header-fulfillment-info.title'),
        helpId: KB_LINKS.FULFILLMENT_INFO_DESIGN_PANEL.id,
        tabs: HEADER_TIME_LOCATION_DESIGN_PANEL_TABS,
        t,
      });
    }
  );

  widgetBuilder.configureConnectedComponents(
    getRole(HEADER_WIDGET_COMPONENT_IDS.fulfillmentPicker),
    (componentBuilder) => {
      componentBuilder.set({
        displayName: t('widget.header.fulfillment-picker.display-name'),
      });
      componentBuilder.behavior().set({ closed: { selectable: true } });
      componentBuilder.gfpp().set('help', { id: KB_LINKS.FULFILLMENT_PICKER_HELP.id });

      configureWidgetDesign({
        widgetBuilder: componentBuilder,
        title: t('design-panel.header-fulfillment.title'),
        helpId: KB_LINKS.FULFILLMENT_PICKER_DESIGN_PANEL.id,
        tabs: HEADER_FULFILLMENT_PICKER_DESIGN_PANEL_TABS,
        t,
      });
    }
  );

  widgetBuilder.configureConnectedComponents(
    getRole(HEADER_WIDGET_COMPONENT_IDS.titleAndDescContainer),
    (componentBuilder) => {
      componentBuilder.set({
        displayName: t('widget.header.title-description.display-name'),
      });
      componentBuilder.behavior().set({ closed: { selectable: true } });
      componentBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('app.gfpp.common.editText'),
          actionId: PANEL_IDS.headerSettings,
        })
        .set('layout', {
          actionId: PANEL_IDS.headerLayout,
        })
        .set('settings', {
          actionId: PANEL_IDS.headerSettings,
        })
        .set('animation', {
          behavior: 'HIDE',
        })
        .set('help', { id: KB_LINKS.HEADER_HELP.id });

      componentBuilder.gfpp('mobile').set('layout', {
        actionId: PANEL_IDS.headerLayout,
      });

      configureWidgetDesign({
        widgetBuilder: componentBuilder,
        title: t('design-panel.header.title'),
        helpId: KB_LINKS.HEADER_DESIGN_PANEL.id,
        tabs: HEADER_TITLE_CONTAINER_DESIGN_PANEL_TABS,
        t,
      });
    }
  );

  const DISABLED_ELEMENTS = [
    HEADER_WIDGET_COMPONENT_IDS.headerTitle,
    HEADER_WIDGET_COMPONENT_IDS.headerDescription,
  ];

  disableElementsSelection(widgetBuilder, DISABLED_ELEMENTS);

  widgetBuilder.configureConnectedComponents(
    getRole(HEADER_WIDGET_COMPONENT_IDS.badgesContainer),
    (componentBuilder) => {
      componentBuilder.set({
        displayName: t('widget.header.badges.display-name'),
      });
      componentBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('menu_olo.gfpp.ordersSettings'),
          actionId: PANEL_IDS.ordersSettings,
        })
        .set('help', { id: KB_LINKS.BADGES_HELP.id });

      badgesConfigureWidgetDesign(componentBuilder, t);
    }
  );
};

const badgesConfigureWidgetDesign = (
  componentBuilder: ConnectedComponentsBuilder,
  t: TFunction
) => {
  configureWidgetDesign({
    widgetBuilder: componentBuilder,
    helpId: KB_LINKS.BADGES_DESIGN_PANEL.id,
    title: t('design-panel.header-badges.title'),
    tabs: HEADER_BADGES_DESIGN_PANEL_TABS,
    t,
  });
};
