import type { IHttpClient, ReportError } from '@wix/yoshi-flow-editor';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import {
  queryOperationGroups,
  deleteOperationGroup,
} from '@wix/ambassador-restaurants-v1-operation-group/http';
import { getMonitoredApiCall } from './getMonitoredApiCall';
import type { OperationGroup } from 'root/types/businessTypes';

export class OperationGroupsClient {
  constructor(
    private httpClient: IHttpClient,
    private fedopsLogger?: FedopsLogger,
    private sentry?: ErrorMonitor,
    private reportError?: ReportError
  ) {}

  getOperationGroups = async (): Promise<OperationGroup[] | undefined> => {
    try {
      return this.httpClient.request(queryOperationGroups({})).then(({ data }) => {
        return data?.operationGroups as unknown as OperationGroup[];
      });
    } catch (e: unknown) {
      console.error(e);
      return undefined;
    }
  };

  deleteOperationGroup = async (operationGroupId: string): Promise<boolean> => {
    try {
      const { error } = await getMonitoredApiCall({
        callback: () => this.httpClient.request(deleteOperationGroup({ operationGroupId })),
        fedops: {
          start: this.fedopsLogger?.deleteOperationGroupStarted,
          end: this.fedopsLogger?.deleteOperationGroupEnded,
        },
        sentry: this.sentry,
        reportError: this.reportError,
      });
      return !error;
    } catch (e: unknown) {
      return false;
    }
  };
}
