import type { FedopsLogger as FedopsLoggerType } from '@wix/fe-essentials-editor';
import type { Interaction } from './types';
import type { IBIReporterService } from 'root/services/biReporterService';

type InteractionOptions = { publishDebugEvent: boolean };

export class FedopsLogger {
  constructor(
    private fedops:
      | FedopsLoggerType<Interaction>
      | { interactionStarted: Function; interactionEnded: Function },
    private msid?: string,
    private biReporterService?: IBIReporterService
  ) {}

  private interactionStarted = (interaction: Interaction, { publishDebugEvent = false } = {}) => {
    this.fedops.interactionStarted(interaction);
    if (publishDebugEvent) {
      this.biReporterService?.reportOloGenericDebugBiEvent({
        subjectType: interaction.toString(),
        value: {
          type: 'interaction started',
          msid: this.msid ?? '',
        },
      });
    }
  };

  private interactionEnded = (interaction: Interaction, { publishDebugEvent = false } = {}) => {
    this.fedops.interactionEnded(interaction);
    if (publishDebugEvent) {
      this.biReporterService?.reportOloGenericDebugBiEvent({
        subjectType: interaction.toString(),
        value: {
          type: 'interaction ended',
          msid: this.msid ?? '',
        },
      });
    }
  };

  restaurantsOrdersFirstInstallStarted = (options?: InteractionOptions) =>
    this.interactionStarted('restaurants-orders-first-install', options);

  restaurantsOrdersFirstInstallEnded = (options?: InteractionOptions) =>
    this.interactionEnded('restaurants-orders-first-install', options);

  openOloElementsPanelStarted = (options?: InteractionOptions) =>
    this.interactionStarted('open-olo-elements-panel', options);

  openOloElementsPanelEnded = (options?: InteractionOptions) =>
    this.interactionEnded('open-olo-elements-panel', options);

  openHeaderElementsPanelStarted = (options?: InteractionOptions) =>
    this.interactionStarted('open-header-elements-panel', options);

  openHeaderElementsPanelEnded = (options?: InteractionOptions) =>
    this.interactionEnded('open-header-elements-panel', options);

  openHeaderSettingsPanelStarted = (options?: InteractionOptions) =>
    this.interactionStarted('open-header-settings-panel', options);

  openHeaderSettingsPanelEnded = (options?: InteractionOptions) =>
    this.interactionEnded('open-header-settings-panel', options);

  openItemLayoutPanelStarted = (options?: InteractionOptions) =>
    this.interactionStarted('open-item-layout-panel', options);

  openItemLayoutPanelEnded = (options?: InteractionOptions) =>
    this.interactionEnded('open-item-layout-panel', options);

  setMenusDataStarted = (options?: InteractionOptions) =>
    this.interactionStarted('set-menus-data', options);

  setMenusDataEnded = (options?: InteractionOptions) =>
    this.interactionEnded('set-menus-data', options);

  loadOloPageStarted = (options?: InteractionOptions) =>
    this.interactionStarted('load-olo-page', options);

  loadOloPageEnded = (options?: InteractionOptions) =>
    this.interactionEnded('load-olo-page', options);

  fetchMenusDataStarted = (options?: InteractionOptions) =>
    this.interactionStarted('fetch-menus-data', options);

  fetchMenusDataEnded = (options?: InteractionOptions) =>
    this.interactionEnded('fetch-menus-data', options);

  fetchOperationDataStarted = (options?: InteractionOptions) =>
    this.interactionStarted('fetch-operation-data', options);

  fetchOperationDataEnded = (options?: InteractionOptions) =>
    this.interactionEnded('fetch-operation-data', options);

  fetchMenuOrdersSettingsStarted = (options?: InteractionOptions) =>
    this.interactionStarted('fetch-menu-orders-settings', options);

  fetchMenuOrdersSettingsEnded = (options?: InteractionOptions) =>
    this.interactionEnded('fetch-menu-orders-settings', options);

  createCartCallStarted = (options?: InteractionOptions) =>
    this.interactionStarted('create-cart', options);

  createCartCallEnded = (options?: InteractionOptions) =>
    this.interactionEnded('create-cart', options);

  addItemsToCartCallStarted = (options?: InteractionOptions) =>
    this.interactionStarted('add-item-to-cart', options);

  addItemsToCartCallEnded = (options?: InteractionOptions) =>
    this.interactionEnded('add-item-to-cart', options);

  removeItemFromCartCallStarted = (options?: InteractionOptions) =>
    this.interactionStarted('remove-item-from-cart', options);

  removeItemFromCartCallEnded = (options?: InteractionOptions) =>
    this.interactionEnded('remove-item-from-cart', options);

  getCartItemsStarted = (options?: InteractionOptions) =>
    this.interactionStarted('get-cart-items', options);

  getCartItemsEnded = (options?: InteractionOptions) =>
    this.interactionEnded('get-cart-items', options);

  addItemsToCartOverallActionStarted = (options?: InteractionOptions) =>
    this.interactionStarted('add-item-to-cart-overall-action', options);

  addItemsToCartOverallActionEnded = (options?: InteractionOptions) =>
    this.interactionEnded('add-item-to-cart-overall-action', options);

  updateCartStarted = (options?: InteractionOptions) =>
    this.interactionStarted('update-cart', options);

  updateCartEnded = (options?: InteractionOptions) => this.interactionEnded('update-cart', options);

  openItemModalStarted = (options?: InteractionOptions) =>
    this.interactionStarted('open-item-modal', options);

  openItemModalEnded = (options?: InteractionOptions) =>
    this.interactionEnded('open-item-modal', options);

  openDispatchModalStarted = (options?: InteractionOptions) =>
    this.interactionStarted('open-dispatch-modal', options);

  openDispatchModalEnded = (options?: InteractionOptions) =>
    this.interactionEnded('open-dispatch-modal', options);

  openErrorModalStarted = (options?: InteractionOptions) =>
    this.interactionStarted('open-error-modal', options);

  openErrorModalEnded = (options?: InteractionOptions) =>
    this.interactionEnded('open-error-modal', options);

  openManageMenusDashboardStarted = (options?: InteractionOptions) =>
    this.interactionStarted('open-manage-menus-dashboard', options);

  openManageMenusDashboardEnded = (options?: InteractionOptions) =>
    this.interactionEnded('open-manage-menus-dashboard', options);

  openMenusAppMarketPanelStarted = (options?: InteractionOptions) =>
    this.interactionStarted('open-menus-appmarket-panel', options);

  openMenusAppMarketPanelEnded = (options?: InteractionOptions) =>
    this.interactionEnded('open-menus-appmarket-panel', options);

  openOrdersSettingsDashboardStarted = (options?: InteractionOptions) =>
    this.interactionStarted('open-orders-settings-dashboard', options);

  openOrdersSettingsDashboardEnded = (options?: InteractionOptions) =>
    this.interactionEnded('open-orders-settings-dashboard', options);

  openItemModalElementsPanelStarted = (options?: InteractionOptions) =>
    this.interactionStarted('open-item-modal-elements-panel', options);

  openItemModalElementsPanelEnded = (options?: InteractionOptions) =>
    this.interactionEnded('open-item-modal-elements-panel', options);

  openDishesElementsPanelStarted = (options?: InteractionOptions) =>
    this.interactionStarted('open-dishes-elements-panel', options);

  openDishesElementsPanelEnded = (options?: InteractionOptions) =>
    this.interactionEnded('open-dishes-elements-panel', options);

  fetchAllModifierGroupsStarted = (options?: InteractionOptions) =>
    this.interactionStarted('fetch-all-modifier-groups', options);

  fetchAllModifierGroupsEnded = (options?: InteractionOptions) =>
    this.interactionEnded('fetch-all-modifier-groups', options);

  fetchAllModifiersStarted = (options?: InteractionOptions) =>
    this.interactionStarted('fetch-all-modifiers', options);

  fetchAllModifiersEnded = (options?: InteractionOptions) =>
    this.interactionEnded('fetch-all-modifiers', options);

  fetchAllFulfillmentsStarted = (options?: InteractionOptions) =>
    this.interactionStarted('fetch-all-fulfillments', options);

  fetchAllFulfillmentsEnded = (options?: InteractionOptions) =>
    this.interactionEnded('fetch-all-fulfillments', options);

  fetchFirstAvailableTimeSlotStarted = (options?: InteractionOptions) =>
    this.interactionStarted('fetch-first-available-time-slot', options);

  fetchFirstAvailableTimeSlotEnded = (options?: InteractionOptions) =>
    this.interactionEnded('fetch-first-available-time-slot', options);

  fetchAvailableTimeSlotsForDateStarted = (options?: InteractionOptions) =>
    this.interactionStarted('fetch-available-time-slots-for-date', options);

  fetchAvailableTimeSlotsForDateEnded = (options?: InteractionOptions) =>
    this.interactionEnded('fetch-available-time-slots-for-date', options);

  fetchAvailableDatesInRangeStarted = (options?: InteractionOptions) =>
    this.interactionStarted('fetch-available-dates-in-range', options);

  fetchAvailableDatesInRangeEnded = (options?: InteractionOptions) =>
    this.interactionEnded('fetch-available-dates-in-range', options);

  createOLOPageStarted = (options?: InteractionOptions) =>
    this.interactionStarted('create-olo-page', options);

  createOLOPageEnded = (options?: InteractionOptions) =>
    this.interactionEnded('create-olo-page', options);

  installAppIfMissingStarted = (options?: InteractionOptions) =>
    this.interactionStarted('install-app-if-missing', options);

  installAppIfMissingEnded = (options?: InteractionOptions) =>
    this.interactionEnded('install-app-if-missing', options);

  installLightboxStarted = (options?: InteractionOptions) =>
    this.interactionStarted('install-lightbox', options);

  installLightboxEnded = (options?: InteractionOptions) =>
    this.interactionEnded('install-lightbox', options);

  installDishItemStarted = (options?: InteractionOptions) =>
    this.interactionStarted('install-dish-item', options);

  installDishItemEnded = (options?: InteractionOptions) =>
    this.interactionEnded('install-dish-item', options);

  installDispatchModalStarted = (options?: InteractionOptions) =>
    this.interactionStarted('install-dispatch-modal', options);

  installDispatchModalEnded = (options?: InteractionOptions) =>
    this.interactionEnded('install-dispatch-modal', options);

  appManifestLoadStarted = (options?: InteractionOptions) =>
    this.interactionStarted('olo-app-manifest-load', options);

  appManifestLoadEnded = (options?: InteractionOptions) =>
    this.interactionEnded('olo-app-manifest-load', options);

  appManifestFirstInstallStarted = (options?: InteractionOptions) =>
    this.interactionStarted('olo-app-manifest-first-install', options);

  appManifestFirstInstallEnded = (options?: InteractionOptions) =>
    this.interactionEnded('olo-app-manifest-first-install', options);

  openOLOSettingsPanelStarted = (options?: InteractionOptions) =>
    this.interactionStarted('open-olo-settings-panel', options);

  openCartButtonSettingsPanelStarted = (options?: InteractionOptions) =>
    this.interactionStarted('open-cart-button-settings-panel', options);

  openOLOSettingsPanelEnded = (options?: InteractionOptions) =>
    this.interactionEnded('open-olo-settings-panel', options);

  deleteOLOPageStarted = (options?: InteractionOptions) =>
    this.interactionEnded('delete-olo-page', options);

  deleteOLOPageEnded = (options?: InteractionOptions) =>
    this.interactionEnded('delete-olo-page', options);

  deleteOperationStarted = (options?: InteractionOptions) =>
    this.interactionStarted('delete-operation', options);

  deleteOperationEnded = (options?: InteractionOptions) =>
    this.interactionEnded('delete-operation', options);

  deleteOperationGroupStarted = (options?: InteractionOptions) =>
    this.interactionStarted('delete-operation-group', options);

  deleteOperationGroupEnded = (options?: InteractionOptions) =>
    this.interactionEnded('delete-operation-group', options);

  createOLOPageByOperationStarted = (options?: InteractionOptions) =>
    this.interactionStarted('create-olo-page-by-operation', options);

  createOLOPageByOperationEnded = (options?: InteractionOptions) =>
    this.interactionEnded('create-olo-page-by-operation', options);

  listMenusAvailabilityStatusByTimeslotStarted = (options?: InteractionOptions) =>
    this.interactionStarted('list-menus-availability-status', options);

  listMenusAvailabilityStatusByTimeslotEnded = (options?: InteractionOptions) =>
    this.interactionEnded('list-menus-availability-status', options);

  listFirstAvailableTimeSlotsForMenusStarted = (options?: InteractionOptions) =>
    this.interactionStarted('list-first-availabile-timeslot-for-menus', options);

  listFirstAvailableTimeSlotsForMenusEnded = (options?: InteractionOptions) =>
    this.interactionEnded('list-first-availabile-timeslot-for-menus', options);
}
