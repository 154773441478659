import type { IBIReporterService } from 'root/services/biReporterService';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function withRetry<T extends (...arg0: any[]) => any>({
  fn,
  args,
  maxTry,
  name,
  delay = 1000,
  retryCount = 1,
  biReporterService,
}: {
  fn: T;
  args: Parameters<T>;
  maxTry: number;
  name: string;
  delay?: number;
  retryCount?: number;
  biReporterService?: IBIReporterService;
}): Promise<Awaited<ReturnType<T>>> {
  try {
    biReporterService?.reportOloEditorInstallationStepsEvent({
      step: 'retry_fetching_data',
      value: `{functionName: ${name}, retryCount: ${retryCount}`,
    });
    const result = await fn(...args);
    if (!result && retryCount <= maxTry) {
      await new Promise((resolve) => setTimeout(resolve, delay));
      return withRetry({ fn, args, maxTry, name, delay, retryCount: retryCount++ });
    }
    return result;
  } catch (e) {
    if (retryCount > maxTry) {
      throw e;
    }
    await new Promise((resolve) => setTimeout(resolve, delay));
    return withRetry({ fn, args, maxTry, name, delay, retryCount: retryCount++ });
  }
}
