import type { FlowEditorSDK, TFunction } from '@wix/yoshi-flow-editor';
import type { IBIReporterService } from 'root/services/biReporterService';
import {
  DISH_ITEM_PRESETS,
  DISPATCH_MODAL_APP_DEF_ID,
  DISPATCH_MODAL_PRESETS,
  ERROR_MODAL_APP_DEF_ID,
  ERROR_MODAL_PRESETS,
  ERROR_MODAL_WIDTH,
  ITEM_MODAL_APP_DEF_ID,
  LIGHTBOX_IDS,
} from 'root/appConsts/consts';
import { addWidgetToLightbox, installLightbox } from './lightbox';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';

export const installLightboxes = async (
  editorSDK: FlowEditorSDK,
  t: TFunction,
  appDefId: string,
  isResponsive: boolean,
  biReporterService: IBIReporterService,
  fedopsLogger: FedopsLogger,
  isFirstInstall: boolean
) => {
  try {
    await installDishItemLightbox(
      editorSDK,
      t,
      appDefId,
      isResponsive,
      biReporterService,
      fedopsLogger,
      isFirstInstall
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Online orders - installDishItemLightbox error: ', e);
  }
  try {
    await installDispatchLightbox(
      editorSDK,
      t,
      appDefId,
      isResponsive,
      biReporterService,
      fedopsLogger,
      isFirstInstall
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Online orders - installDispatchLightbox error: ', e);
  }
  try {
    await installErrorLightbox(
      editorSDK,
      t,
      appDefId,
      isResponsive,
      biReporterService,
      fedopsLogger,
      isFirstInstall
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Online orders - installErrorLightbox error: ', e);
  }
};

const installDishItemLightbox = async (
  editorSDK: FlowEditorSDK,
  t: Function,
  appDefId: string,
  isResponsive: boolean,
  biReporterService: IBIReporterService,
  fedopsLogger: FedopsLogger,
  isFirstInstall: boolean
) => {
  fedopsLogger.installDishItemStarted();
  const lightboxRef = await installLightbox(
    editorSDK,
    appDefId,
    ITEM_MODAL_APP_DEF_ID,
    t('app.lightbox.itemModal.title'),
    LIGHTBOX_IDS.itemModal,
    isResponsive,
    biReporterService,
    fedopsLogger,
    isFirstInstall
  );
  addWidgetToLightbox(
    editorSDK,
    lightboxRef,
    ITEM_MODAL_APP_DEF_ID,
    DISH_ITEM_PRESETS,
    isResponsive,
    biReporterService
  );
  fedopsLogger.installDishItemEnded();
};

const installDispatchLightbox = async (
  editorSDK: FlowEditorSDK,
  t: Function,
  appDefId: string,
  isResponsive: boolean,
  biReporterService: IBIReporterService,
  fedopsLogger: FedopsLogger,
  isFirstInstall: boolean
) => {
  fedopsLogger.installDispatchModalStarted();
  const lightboxRef = await installLightbox(
    editorSDK,
    appDefId,
    DISPATCH_MODAL_APP_DEF_ID,
    t('app.lightbox.dispatchModal.title'),
    LIGHTBOX_IDS.dispatchModal,
    isResponsive,
    biReporterService,
    fedopsLogger,
    isFirstInstall
  );
  addWidgetToLightbox(
    editorSDK,
    lightboxRef,
    DISPATCH_MODAL_APP_DEF_ID,
    DISPATCH_MODAL_PRESETS,
    isResponsive,
    biReporterService
  );
  fedopsLogger.installDispatchModalEnded();
};

const installErrorLightbox = async (
  editorSDK: FlowEditorSDK,
  t: Function,
  appDefId: string,
  isResponsive: boolean,
  biReporterService: IBIReporterService,
  fedopsLogger: FedopsLogger,
  isFirstInstall: boolean
) => {
  const lightboxRef = await installLightbox(
    editorSDK,
    appDefId,
    ERROR_MODAL_APP_DEF_ID,
    t('app.lightbox.errorModal.title'),
    LIGHTBOX_IDS.errorModal,
    isResponsive,
    biReporterService,
    fedopsLogger,
    isFirstInstall,
    ERROR_MODAL_WIDTH
  );
  return addWidgetToLightbox(
    editorSDK,
    lightboxRef,
    ERROR_MODAL_APP_DEF_ID,
    ERROR_MODAL_PRESETS,
    isResponsive,
    biReporterService,
    ERROR_MODAL_WIDTH
  );
};

export const installLightBoxByTpaAppId = {
  [LIGHTBOX_IDS.itemModal]: installDishItemLightbox,
  [LIGHTBOX_IDS.dispatchModal]: installDispatchLightbox,
  [LIGHTBOX_IDS.errorModal]: installErrorLightbox,
};
