import { EditorSubType } from '@wix/editor-platform-sdk-types';
import type { PopulatedMenu } from '../types/menusTypes';
import type { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  Hosting,
  LiveSiteDispatchModalOpenedSelectedRadioButton,
  LiveSiteErrorDisplayedInDispatchModalErrorType,
} from '@wix/restaurants-bi';
import { ErrorType } from 'root/components/DispatchModal/dispatchModalUtils';
import { SchedulingType } from 'root/types/businessTypes';
import { cartState } from 'root/states/cartState';

export const getAmountOfSectionsAndItems = (menus: PopulatedMenu[]) => {
  const { numOfSections, numOfItems } = menus.reduce(
    (acc, menu) => {
      acc.numOfSections += menu.sections?.length;
      menu.sections?.forEach((section) => {
        acc.numOfItems += section.itemIds?.length;
      });
      return acc;
    },
    { numOfSections: 0, numOfItems: 0 }
  );

  return { numOfSections, numOfItems };
};

export const getAmountOfItemsInCart = () => {
  let quantity = 0;

  cartState.cartLineItems?.forEach((value) => {
    value.map((item) => (quantity += item.quantity || 0));
  });

  return quantity;
};

export const getHostingEnvironment = (
  environment: PlatformControllerFlowAPI['environment'],
  editorSubType?: EditorSubType
) => {
  if (environment.isEditor) {
    return Hosting.EDITOR;
  } else if (environment.isEditorX) {
    return editorSubType === EditorSubType.EditorX ? Hosting.EDITOR_X : Hosting.STUDIO;
  } else if (environment.isADI) {
    return Hosting.ADI;
  } else if (environment.isPreview) {
    return Hosting.PREVIEW;
  } else if (environment.isMobile) {
    return Hosting.MOBILE;
  } else {
    return Hosting.VIEWER;
  }
};

export const getErrorType = (errorType: ErrorType) => {
  if (errorType === ErrorType.NO_ADDRESS) {
    return LiveSiteErrorDisplayedInDispatchModalErrorType.NO_ADDRESS;
  } else {
    return LiveSiteErrorDisplayedInDispatchModalErrorType.NO_FULFILLMENTS;
  }
};

export const getSelectedRadioButton = (schedulingType?: SchedulingType) => {
  switch (schedulingType) {
    case SchedulingType.ASAP:
      return LiveSiteDispatchModalOpenedSelectedRadioButton.ASAP_RADIO_BUTTON;
    case SchedulingType.ASAP_AND_FUTURE:
      return LiveSiteDispatchModalOpenedSelectedRadioButton.FUTURE_RADIO_BUTTON;
    default:
      return undefined;
  }
};
