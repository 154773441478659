import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { getRole } from '../../utils/utils';
import type {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  TFunction,
  WidgetBuilder,
} from '@wix/yoshi-flow-editor';
import { OLO_WIDGET_DESIGN_PANEL_TABS } from '../../appConsts/designPanelTabs';
import { HELP_IDS, PANEL_IDS } from '../../appConsts/consts';
import {
  CART_BUTTON_COMPONENT_IDS,
  NAVIGATION_TOOLBAR_IDS,
  OLO_WIDGET_COMPONENT_IDS,
} from 'root/appConsts/blocksIds';
import { configureWidgetDesign, disableElementSelection } from 'root/editor/editor.utils';
import { KB_LINKS } from 'root/appConsts/kb-links';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder: WidgetBuilder,
  _editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI
) => {
  const { translations, experiments } = flowAPI;
  const t = translations.t as TFunction;
  widgetBuilder.set({
    displayName: t('app.widget.title'),
    nickname: 'OLOWidget',
  });

  widgetBuilder.behavior().set({ removable: false });

  const gfppBuilder = widgetBuilder.gfpp();

  gfppBuilder
    .set('mainAction1', {
      label: t('menu_olo.gfpp.manageMenus'),
      actionId: PANEL_IDS.manageMenus,
    })
    .set('mainAction2', {
      label: t('menu_olo.gfpp.ordersSettings'),
      actionId: PANEL_IDS.ordersSettings,
    })
    .set('design', { behavior: 'DEFAULT' })
    .set('add', { actionId: PANEL_IDS.oloElementsPanel })
    .set('connect', { behavior: 'HIDE' })
    .set('help', { id: HELP_IDS.OLO });

  gfppBuilder.set('settings', { actionId: PANEL_IDS.menuSettings });

  const tabs = [...OLO_WIDGET_DESIGN_PANEL_TABS];
  if (tabs[2].label !== 'design-panel.tabs.olo.navigation-background') {
    tabs.splice(2, 0, {
      label: 'design-panel.tabs.olo.navigation-background',
      roles: [NAVIGATION_TOOLBAR_IDS.box, CART_BUTTON_COMPONENT_IDS.viewCartBox],
    });
  }

  configureWidgetDesign({
    widgetBuilder,
    title: t('design-panel.title'),
    helpId: KB_LINKS.OLO_WIDGET_DESIGN_PANEL.id,
    tabs,
    t,
  });

  widgetBuilder.configureConnectedComponents(
    getRole(OLO_WIDGET_COMPONENT_IDS.header),
    (componentBuilder) => {
      disableElementSelection(componentBuilder);
    }
  );

  widgetBuilder.configureConnectedComponents(
    getRole(NAVIGATION_TOOLBAR_IDS.cartButton),
    (componentBuilder) => {
      disableElementSelection(componentBuilder);
    }
  );

  widgetBuilder.configureConnectedComponents(
    getRole(OLO_WIDGET_COMPONENT_IDS.navigationMenu),
    (_componentBuilder) => {
      // navigation gfpp on classic editor not working without this empty override
      _componentBuilder.set({
        displayName: t('widget.navigation.container.display-name'),
      });
    }
  );
};
