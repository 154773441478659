import { listPopups } from '@wix/ambassador-document-v1-popup/http';
import type { IHttpClient } from '@wix/yoshi-flow-editor';

export const getAllPopups = async (httpClient: IHttpClient) => {
  try {
    const popups = await httpClient.request(listPopups({}));
    return popups.data?.popups ?? [];
  } catch (e) {
    console.error(e);
    return [];
  }
};
