import { makeAutoObservable, runInAction, configure } from 'mobx';
import type { CartLineItem } from '../services/cartService';
import type { LineItem } from '@wix/ambassador-ecom-v1-cart/types';

configure({ isolateGlobalState: true });

class CartState {
  private items: Map<string, CartLineItem[]> = new Map();
  constructor() {
    makeAutoObservable(this);
  }

  get cartLineItems() {
    return this.items;
  }

  setCartLineItemsMap(items: Map<string, CartLineItem[]>) {
    this.items = items;
  }

  setCartLineItems = ({ lineItems = [] }: { lineItems: LineItem[] }) => {
    runInAction(() => {
      this.setCartLineItemsMap(new Map());
      lineItems.forEach((cartItem) => {
        const { quantity, catalogReference } = cartItem;
        const { options, catalogItemId: itemId } = catalogReference ?? {};
        const { menuId, menuSectionId } = options ?? {};
        const cartLineItemsKey = `${menuId}_${menuSectionId}_${itemId}`;
        const cartLineItems = this.items.get(cartLineItemsKey) ?? [];
        return this.items.set(cartLineItemsKey as string, [
          ...cartLineItems,
          {
            id: cartItem.id ?? '',
            catalogItemId: itemId as string,
            quantity,
            options,
          },
        ]);
      });
    });
  };
}

export const cartState = new CartState();
