import type { IHttpClient, ReportError } from '@wix/yoshi-flow-editor';
import {
  listOperations,
  deleteOperation,
  queryOperation,
} from '@wix/ambassador-restaurants-operations-v1-operation/http';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import type { Operation } from '../types/businessTypes';
import { OperationAdapter } from './utils/OperationAdapter';
import { getMonitoredApiCall } from './utils/getMonitoredApiCall';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';

export class OperationsClient {
  constructor(
    private httpClient: IHttpClient,
    private fedopsLogger?: FedopsLogger,
    private sentry?: ErrorMonitor,
    private reportError?: ReportError
  ) {}

  getOperation = async (
    operationIdPromise?: Promise<string | undefined>
  ): Promise<Operation | undefined> => {
    try {
      const operationId = await operationIdPromise;

      return this.httpClient.request(listOperations({})).then(({ data }) => {
        if (operationId) {
          return OperationAdapter(
            data?.operations?.find((operation) => operation?.id === operationId) ?? {}
          );
        } else {
          if (data?.operations?.[0]) {
            return OperationAdapter(data.operations[0]);
          }
        }
        return undefined;
      });
    } catch (e: unknown) {
      console.error(e);
      return undefined;
    }
  };

  getOperations = async (): Promise<Operation[] | undefined> => {
    try {
      return this.httpClient
        .request(listOperations({}))
        .then(({ data }) =>
          data?.operations?.length
            ? data?.operations
                ?.filter((operation) => operation.enabled)
                .map((operation) => OperationAdapter(operation ?? {}))
            : undefined
        );
    } catch (e: unknown) {
      console.error(e);
      return undefined;
    }
  };

  getOperationsByGroupId = async (
    operationGroupIdPromise: Promise<string | undefined>
  ): Promise<Operation[] | undefined> => {
    try {
      const operationGroupId = await operationGroupIdPromise;
      return this.httpClient
        .request(
          queryOperation({
            query: {
              filter: {
                operationGroupId,
                $not: { 'businessLocationDetails.archived': true },
                enabled: true,
              },
            },
          })
        )
        .then(({ data }) =>
          data?.operations?.length
            ? data?.operations.map((operation) => OperationAdapter(operation ?? {}))
            : undefined
        );
    } catch (e: unknown) {
      console.error(e);
      return undefined;
    }
  };

  deleteOperation = async (operationId: string): Promise<boolean> => {
    try {
      const { error } = await getMonitoredApiCall({
        callback: () => this.httpClient.request(deleteOperation({ operationId })),
        fedops: {
          start: this.fedopsLogger?.deleteOperationStarted,
          end: this.fedopsLogger?.deleteOperationEnded,
        },
        sentry: this.sentry,
        reportError: this.reportError,
      });
      return !error;
    } catch (e: unknown) {
      return false;
    }
  };
}
