import type {
  ComponentRef,
  FlowEditorSDK,
  TFunction,
  FlowPlatformOptions,
} from '@wix/yoshi-flow-editor';
import {
  getCurrentPage,
  getIsAppInstalled,
  getOperationByPageId,
  getParentWidgetById,
  isMobileViewport,
} from './editor.utils';
import { CART_BUTTON_CONTROLLER_WIDGET_ID, PANEL_IDS, PANEL_NAMES } from 'root/appConsts/consts';
import {
  openDishesElementsPanel,
  openHeaderElementsPanel,
  openItemModalElementsPanel,
  openOloElementsPanel,
} from 'root/panels/elementsPanel/elementsActions';
import { APP_DEF_IDS } from '@wix/restaurants-consts';
import {
  OLODashboardPanel,
  openManageMenuDashboardPanel,
  openMenusAppMarketPanel,
} from 'root/utils/openDashboardPanels';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import type { IPanelsApi } from '@wix/blocks-widget-services/panels';
import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';

const openBlocksPanelWrapper = async (
  panelsApi: IPanelsApi,
  panelName: string,
  componentRef: ComponentRef
) => {
  await panelsApi.openBlocksPanel(panelName, componentRef);
};

export const registerGfppEvents = async (
  editorSDK: FlowEditorSDK,
  fedopsLogger: FedopsLogger,
  t: TFunction,
  options: FlowPlatformOptions
) => {
  const panelsApi = await new PanelsApiFactory().createPanelsApi({
    editorSDK,
    editorType: options.origin.type,
    essentials: options.essentials,
  });
  await editorSDK.addEventListener('widgetGfppClicked', async (event) => {
    const { id, componentRef } = event.detail;
    const isMobile = await isMobileViewport(editorSDK);

    switch (id) {
      case PANEL_IDS.oloElementsPanel: {
        fedopsLogger.openOloElementsPanelStarted();
        await openOloElementsPanel(editorSDK, componentRef, t as TFunction);

        fedopsLogger.openOloElementsPanelEnded();
        break;
      }
      case PANEL_IDS.manageMenus: {
        const isAppInstalled = await getIsAppInstalled(editorSDK, APP_DEF_IDS.menus);
        if (isAppInstalled) {
          fedopsLogger.openManageMenusDashboardStarted();
          openManageMenuDashboardPanel(editorSDK);
          fedopsLogger.openManageMenusDashboardEnded();
        } else {
          fedopsLogger.openMenusAppMarketPanelStarted();
          openMenusAppMarketPanel(editorSDK);
          fedopsLogger.openMenusAppMarketPanelEnded();
        }
        break;
      }
      case PANEL_IDS.ordersSettings: {
        fedopsLogger.openOrdersSettingsDashboardStarted();
        openOLOSettings(editorSDK);
        fedopsLogger.openOrdersSettingsDashboardEnded();
        break;
      }
      case PANEL_IDS.itemModalElements: {
        fedopsLogger.openItemModalElementsPanelStarted();
        await openItemModalElementsPanel(editorSDK, componentRef, t as TFunction);
        fedopsLogger.openItemModalElementsPanelEnded();
        break;
      }
      case PANEL_IDS.itemLayout: {
        fedopsLogger.openItemLayoutPanelStarted();
        const panelName = isMobile ? PANEL_NAMES.itemLayoutMobile : PANEL_NAMES.itemLayout;
        openBlocksPanelWrapper(panelsApi, panelName, componentRef);
        fedopsLogger.openItemLayoutPanelEnded();
        break;
      }
      case PANEL_IDS.dishesElementsPanel: {
        fedopsLogger.openDishesElementsPanelStarted();
        await openDishesElementsPanel(editorSDK, componentRef, t as TFunction);
        fedopsLogger.openDishesElementsPanelEnded();
        break;
      }
      case PANEL_IDS.menuSettings: {
        fedopsLogger.openOLOSettingsPanelStarted();
        await editorSDK.editor.openComponentPanel(APP_DEF_IDS.orders, {
          title: t('settings-panel.olo.title'),
          url: getPanelUrl('OLO', 'MenuSettings'),
          width: 288,
          height: 262,
          componentRef,
          initialData: {
            componentRef,
          },
          // TODO - add KB id
          // helpId: '0ee67a30-0d07-4162-9ed6-59ecfe602e09',
        });
        break;
      }
    }
  });

  await editorSDK.addEventListener('componentGfppClicked', async (event) => {
    const { id, componentRef } = event.detail;
    const isMobile = await isMobileViewport(editorSDK);

    switch (id) {
      case PANEL_IDS.cartButtonSettings: {
        fedopsLogger.openCartButtonSettingsPanelStarted();
        const widgetRef = await getParentWidgetById(
          editorSDK,
          componentRef,
          CART_BUTTON_CONTROLLER_WIDGET_ID
        );
        await editorSDK.editor.openComponentPanel(APP_DEF_IDS.orders, {
          title: t('settings-panel.cart-button.title'),
          url: getPanelUrl('CartButton', 'Settings'),
          width: 288,
          height: 229,
          componentRef,
          initialData: {
            componentRef: widgetRef,
          },
        });
        break;
      }
      case PANEL_IDS.headerSettings: {
        fedopsLogger.openHeaderSettingsPanelStarted();
        openBlocksPanelWrapper(panelsApi, PANEL_NAMES.headerSettings, componentRef);
        fedopsLogger.openHeaderSettingsPanelEnded();
        break;
      }
      case PANEL_IDS.headerLayout: {
        fedopsLogger.openHeaderSettingsPanelStarted();
        const panelName = isMobile ? PANEL_NAMES.headerLayoutMobile : PANEL_NAMES.headerLayout;
        openBlocksPanelWrapper(panelsApi, panelName, componentRef);
        fedopsLogger.openHeaderSettingsPanelEnded();
        break;
      }
      case PANEL_IDS.imageLayout: {
        fedopsLogger.openHeaderSettingsPanelStarted();
        openBlocksPanelWrapper(panelsApi, PANEL_NAMES.imageLayout, componentRef);
        fedopsLogger.openHeaderSettingsPanelEnded();
        break;
      }
      case PANEL_IDS.headerElements: {
        fedopsLogger.openHeaderElementsPanelStarted();
        await openHeaderElementsPanel(editorSDK, componentRef, t as TFunction);
        fedopsLogger.openHeaderElementsPanelEnded();
        break;
      }
      case PANEL_IDS.itemLayout: {
        fedopsLogger.openItemLayoutPanelStarted();
        openBlocksPanelWrapper(panelsApi, PANEL_NAMES.itemLayout, componentRef);
        fedopsLogger.openItemLayoutPanelEnded();
        break;
      }
      case PANEL_IDS.manageMenus: {
        fedopsLogger.openManageMenusDashboardStarted();
        openManageMenuDashboardPanel(editorSDK);
        fedopsLogger.openManageMenusDashboardEnded();
        break;
      }
      case PANEL_IDS.ordersSettings: {
        fedopsLogger.openOrdersSettingsDashboardStarted();
        openOLOSettings(editorSDK);
        fedopsLogger.openOrdersSettingsDashboardEnded();
        break;
      }
    }
  });
};

const openOLOSettings = async (editorSDK: FlowEditorSDK) => {
  const ordersPage = await getCurrentPage(editorSDK);
  const operationId = getOperationByPageId(ordersPage?.tpaPageId);
  OLODashboardPanel(editorSDK).openSettings(operationId);
};
