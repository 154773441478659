import type { EditorSDK, ExportsFn, ContextParams } from '@wix/platform-editor-sdk';
import type {
  FlowEditorSDK,
  IHttpClient,
  PlatformControllerFlowAPI,
  VisitorLogger,
} from '@wix/yoshi-flow-editor';
import { APP_DEF_IDS } from '@wix/restaurants-consts';
import { navigateToPage } from './editor.utils';
import { PAGE_DATA } from '../appConsts/consts';
import { OperationsClient } from 'root/api/operationClient';
import { BIReporterService } from 'root/services/biReporterService';
import { withRetry } from 'root/utils/retryUtils';

export const exportsImpl: ExportsFn = (editorSDK: EditorSDK, context: ContextParams) => {
  const biLogger = context.essentials.biLoggerFactory().logger() as unknown as VisitorLogger;
  const biLoggerService = BIReporterService({
    biLogger,
    environment: editorSDK.environment as unknown as PlatformControllerFlowAPI['environment'],
  });

  return {
    editor: {
      async appInstalled({ appDefinitionId }) {
        if (appDefinitionId === APP_DEF_IDS.orders) {
          // eslint-disable-next-line no-console
          console.log(
            'Online ordering - dependency app was installed. appDefinitionId:',
            appDefinitionId,
            'navigating to the online ordering page'
          );
          return navigateToPage(editorSDK as FlowEditorSDK, PAGE_DATA.pageId);
        }
      },
    },
    public: {
      getOloPageIds: async (httpClient: IHttpClient) => {
        const operations = await withRetry({
          fn: new OperationsClient(httpClient).getOperations,
          args: [],
          name: 'getOperations',
          maxTry: 5,
        });
        const pageIds =
          operations
            ?.map((operation) => [
              `${PAGE_DATA.pageId}-${operation.id}`,
              `${PAGE_DATA.pageId}-${operation.operationGroupId}`,
            ])
            .flat() ?? [];

        return [...new Set(pageIds)];
      },
    },
    private: {
      reportOloEditorMenuReorderBiEvent: biLoggerService.reportOloEditorMenuReorderBiEvent,
      reportPanelLoaded: biLoggerService.reportPanelLoaded,
      reportPanelEditAction: biLoggerService.reportPanelEditAction,
    },
  };
};
