import { makeAutoObservable, toJS, runInAction, configure } from 'mobx';
import type { Item, PopulatedMenu, PopulatedSection } from 'root/types/menusTypes';
import { AvailabilityStatus } from '@wix/ambassador-restaurants-menu-settings-v1-menu-ordering-settings/types';
import type { Address } from '@wix/ambassador-restaurants-operations-v1-operation/types';
import { getSortedArrayByIds, sortMenusByAvailability } from 'root/utils/utils';
import type { IOrdersSettingsService } from 'root/services/ordersSettingsService';
import { DispatchType } from 'root/types/businessTypes';
import type { DispatchInfo, TimeSlotsPerMenu } from 'root/types/businessTypes';
import { dispatchState } from 'root/states/DispatchState';
import type { MenuAvailability } from '@wix/restaurants-olo-operations-client-commons';
import { getMenuAvailabilityStatusText } from 'root/utils/menusUtils';
import type { TFunction } from '@wix/yoshi-flow-editor';
import type {
  ItemModalAvailabilityStatusKeys,
  MenuAvailabilityStatusKeys,
} from 'root/availabilityStatusKeys';
import { TRUNCATE_FROM_THRESHOLD, DEFAULT_MENU_SIZE } from 'root/appConsts/consts';

configure({ isolateGlobalState: true });

export class MenuState {
  menu: PopulatedMenu;
  sectionSizes: Record<string, number> = {};

  constructor(menu: PopulatedMenu) {
    this.menu = menu;
    this.sectionSizes = menu.sections.reduce((acc, section) => {
      acc[section.id] = section.itemIds?.length ?? 0;
      return acc;
    }, {} as Record<string, number>);
    makeAutoObservable(this);
  }

  get id() {
    return this.menu.id;
  }

  get size() {
    return Object.values(this.sectionSizes).reduce((acc, size) => acc + size, 0);
  }

  get sectionMap() {
    return this.menu.sections.reduce((acc, section) => {
      acc.set(section.id, section);
      return acc;
    }, new Map<string, PopulatedSection>());
  }

  setSize(size: number) {
    if (this.size !== size) {
      let itemsLeft = size;
      this.sectionSizes = this.menu.sections.reduce((acc, section) => {
        acc[section.id] = Math.min(section.itemIds?.length ?? 0, itemsLeft);
        itemsLeft -= (section.itemIds || []).length;
        itemsLeft = Math.max(itemsLeft, 0);
        return acc;
      }, {} as Record<string, number>);
    }
  }

  get isAvailable() {
    const availabilityStatus = menusState.availabilityStatus[this.menu.id];
    return availabilityStatus !== AvailabilityStatus.UNAVAILABLE;
  }

  get availability() {
    return menusState.menusAvailability[this.menu.id];
  }

  get menuDto() {
    const menu = toJS(this.menu);
    const sections = this.menu.sections.map((section) => {
      const sectionSize = this.sectionSizes[section.id];
      return {
        ...section,
        size: sectionSize,
        truncated: (section.itemIds?.length ?? 0) > sectionSize,
      };
    });
    return {
      _id: this.menu.id,
      ...menu,
      sections,
    };
  }

  get length() {
    return this.menu.size;
  }

  get nextAvailableTimeslot() {
    const timeSlotsPerMenu = menusState.timeSlotsPerMenu?.[this.menu.id];
    return timeSlotsPerMenu
      ? !!timeSlotsPerMenu[dispatchState.selectedDispatchType.toLowerCase()]
      : undefined;
  }

  getAvailabilityStatus({
    locale,
    timezone,
    t,
    keys,
  }: {
    locale: string;
    timezone: string;
    t: TFunction;
    keys: MenuAvailabilityStatusKeys | ItemModalAvailabilityStatusKeys;
  }) {
    const hasNextAvailability = !!this?.nextAvailableTimeslot;
    return !this.isAvailable
      ? getMenuAvailabilityStatusText({
          availability: this.availability || {},
          locale,
          timezone,
          t,
          keys: hasNextAvailability ? keys.hasNextAvailabilityKeys : keys.noNextAvailabilityKeys,
        })
      : {};
  }

  populate(itemMap: Map<string, Item>) {
    const menuSections =
      this.menu.sections?.map((section) => {
        const itemIds = (section.itemIds ?? []).filter((itemId) => itemMap.has(itemId));
        return {
          ...section,
          itemIds,
          items: itemIds.map((itemId) => itemMap.get(itemId)) as Item[],
        };
      }) ?? [];
    this.menu.sections = menuSections;
  }
}

class MenusState {
  private menusMap: Map<string, MenuState> = new Map();
  menus: MenuState[] = [];
  menusOrder: string[] = [];
  availabilityStatus: Record<string, AvailabilityStatus> = {};
  timeSlotsPerMenu: TimeSlotsPerMenu | undefined;
  menusAvailability: Record<string, MenuAvailability> = {};
  hasError = false;
  isMobile = false;
  size = DEFAULT_MENU_SIZE;
  private ordersSettingsService?: IOrdersSettingsService;

  constructor() {
    makeAutoObservable(this);
  }

  setMenus(menus: PopulatedMenu[], menusOrder?: string[]) {
    runInAction(() => {
      this.menus = menus.map((menu) => new MenuState(menu));
      const menusSize = this.menus.reduce((acc, menu) => acc + menu.size, 0);
      if (menusSize > TRUNCATE_FROM_THRESHOLD) {
        this.truncate();
      } else {
        this.size = menusSize;
      }
      if (menusOrder) {
        this.menusOrder = menusOrder;
      }
      this.sortMenus();
      this.menusMap = this.menus.reduce((acc, menuState) => {
        acc.set(menuState.menuDto.id, menuState);
        return acc;
      }, new Map<string, MenuState>());
    });
  }

  getMenu(menuId: string) {
    return this.menusMap.get(menuId);
  }

  private truncate() {
    let itemsLeft = this.size;
    this.menus = this.menus.map((menuState) => {
      if (itemsLeft < menuState.menu.size) {
        menuState.setSize(itemsLeft);
        itemsLeft = 0;
      } else {
        itemsLeft -= menuState.size;
        itemsLeft = Math.max(itemsLeft, 0);
        if (menuState.size !== menuState.menu.size) {
          menuState.setSize(menuState.menu.size);
        }
      }
      return menuState;
    });
  }

  setOrdersSettingsService(ordersSettingsService: IOrdersSettingsService) {
    this.ordersSettingsService = ordersSettingsService;
  }

  setMenusAvailability(menusAvailability: Record<string, MenuAvailability>) {
    this.menusAvailability = menusAvailability;
  }

  async updateFirstAvailableTimeSlotsForMenus(operationId?: string, deliveryAddress?: Address) {
    return this.ordersSettingsService
      ?.fetchFirstAvailableTimeSlotsForMenus({
        operationId,
        deliveryAddress,
      })
      .then((timeSlotsPerMenu) => {
        runInAction(() => {
          this.timeSlotsPerMenu = timeSlotsPerMenu;
        });
      });
  }

  setFirstAvailableTimeSlotsForMenus(timeSlotsPerMenu: TimeSlotsPerMenu) {
    runInAction(() => {
      this.timeSlotsPerMenu = timeSlotsPerMenu;
      this.sortMenus();
    });
  }

  async updateAvailabilityStatus(
    operationId: string | undefined,
    dispatchInfo: DispatchInfo,
    dispatchType: DispatchType
  ) {
    const menuAvailabilityPromise = this.ordersSettingsService
      ?.fetchMenusAvailabilityStatusByDispatchInfo({
        operationId,
        dispatchInfo,
      })
      .then((menusAvailabilityStatus) => {
        runInAction(() => {
          this.availabilityStatus = menusAvailabilityStatus;
          this.sortMenus();
        });
      });

    const deliveryAddress =
      dispatchType === DispatchType.DELIVERY ? dispatchInfo.address : undefined;
    const timeSlotsForMenusPromise = this.updateFirstAvailableTimeSlotsForMenus(
      operationId,
      deliveryAddress
    );
    await Promise.all([menuAvailabilityPromise, timeSlotsForMenusPromise]);
  }

  setAvailabilityStatus(availabilityStatus: Record<string, AvailabilityStatus>) {
    runInAction(() => {
      this.availabilityStatus = availabilityStatus;
      this.sortMenus();
    });
  }

  get sortedMenusDto() {
    const menus = this.menus.map((menu: MenuState) => menu.menuDto);
    return menus;
  }

  private sortMenus() {
    const sortedMenus = getSortedArrayByIds(this.menus, this.menusOrder);
    this.menus = sortMenusByAvailability(
      sortedMenus,
      this.availabilityStatus,
      this.timeSlotsPerMenu || {},
      dispatchState.selectedDispatchType
    );
  }

  get hasAtLeastOneMenuWithSections() {
    return this.sortedMenusDto.some((menu) => menu.sectionIds.length > 0);
  }

  get isEmpty() {
    return this.sortedMenusDto.length === 0;
  }

  orderMenus(menusOrder: string[] = []) {
    this.menusOrder = menusOrder;
    this.sortMenus();
  }

  setHasError(hasError: boolean) {
    this.hasError = hasError;
  }

  populate(itemMap: Map<string, Item>) {
    this.menus.forEach((menu) => menu.populate(itemMap));
  }
}

const menusState = new MenusState();

export { menusState };
