import type { TFunction } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { CART_BUTTON_COMPONENT_IDS } from 'root/appConsts/blocksIds';
import { PANEL_IDS } from 'root/appConsts/consts';
import { CART_BUTTON_DESIGN_PANEL_TABS } from 'root/appConsts/designPanelTabs';
import { KB_LINKS } from 'root/appConsts/kb-links';
import { configureWidgetDesign, disableElementsSelection } from 'root/editor/editor.utils';
import { getRole } from 'root/utils/utils';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder, _, flowAPI) => {
  const { translations } = flowAPI;
  const t = translations.t as TFunction;

  widgetBuilder.set({
    displayName: t('cart-button.widget.title'),
    nickname: 'cartButton',
  });

  widgetBuilder.behavior().set({ preventHide: true, removable: false });

  configureWidgetDesign({
    widgetBuilder,
    title: t('design-gfpp.cart-button.designpanel.header'),
    tabs: CART_BUTTON_DESIGN_PANEL_TABS,
    helpId: KB_LINKS.CART_BUTTON_DESIGN_PANEL.id,
    t,
  });

  widgetBuilder.configureConnectedComponents(
    getRole(CART_BUTTON_COMPONENT_IDS.container),
    (componentBuilder) => {
      componentBuilder.set({ displayName: t('cart-button.widget.title') });
      componentBuilder.behavior().set({ closed: { selectable: true } });
      componentBuilder
        .gfpp()
        .set('help', { id: KB_LINKS.CART_BUTTON_HELP.id })
        .set('add', { behavior: 'HIDE' });

      componentBuilder
        .gfpp('desktop')
        .set('mainAction1', {
          label: t('settings-panel.cart-button.title'),
          actionId: PANEL_IDS.cartButtonSettings,
        })
        .set('design', { behavior: 'DEFAULT' });

      componentBuilder.gfpp('mobile').set('design', { behavior: 'DEFAULT' });

      configureWidgetDesign({
        widgetBuilder: componentBuilder,
        title: t('design-gfpp.cart-button.designpanel.header'),
        tabs: CART_BUTTON_DESIGN_PANEL_TABS,
        helpId: KB_LINKS.CART_BUTTON_DESIGN_PANEL.id,
        t,
      });
    }
  );

  disableElementsSelection(widgetBuilder, [
    CART_BUTTON_COMPONENT_IDS.viewCartBox,
    CART_BUTTON_COMPONENT_IDS.divider,
    CART_BUTTON_COMPONENT_IDS.button,
  ]);
};
