import type {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  TFunction,
  WidgetBuilder,
} from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { ERROR_MODAL_DESIGN_PANEL_TABS } from 'root/appConsts/designPanelTabs';
import { configureWidgetDesign } from 'root/editor/editor.utils';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI
) => {
  const t = flowAPI.translations.t as TFunction;
  widgetBuilder.set({
    displayName: 'Order Error Popup',
    nickname: 'ModalWidget',
  });

  widgetBuilder.behavior().set({
    rotatable: false,
    canReparent: false,
    duplicatable: false,
    preventHide: true,
    removable: false,
    resizable: false,
    toggleShowOnAllPagesEnabled: false,
  });

  widgetBuilder
    .gfpp()
    .set('mainAction1', { label: t('error-modal.gfpp.main'), actionType: 'design', actionId: '' })
    .set('design', { behavior: 'DEFAULT' });

  configureWidgetDesign({
    widgetBuilder,
    title: t('design-panel.order-error.title'),
    tabs: ERROR_MODAL_DESIGN_PANEL_TABS,
    t,
  });
};
